import Container from './Components/Container';
import Header from './Components/Header';
import ProjectCard from './Components/ProjectCard';
import projects from './data/projects.json';
import {shuffle} from 'lodash';
// <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">

function App() {
  return (
    <>
      <Header />
      <Container>
          <div className="flex flex-col flex-wrap lg:h-192">
              {shuffle(projects).map((project, index) => <ProjectCard data={project} key={index} />)}
          </div>
      </Container>
    </>
  );
}

export default App;

import Project from "../interfaces/Project";

interface cardProps {
    data: Project;
}

const ProjectCard: React.FC<cardProps> = ({data}) => {
    return (
        <div className="rounded border-2 border-purple-200 hover:border-purple-300 animate flex flex-col h-m-content lg:w-1/3 m-3">
            {data.imgSrc && <img src={data.imgSrc} className="w-full object-cover" alt="" />}
            <div className="p-3 flex flex-col">
                <h3 className="text-xl lg:text-2xl font-semibold flex-grow-0">{data.name} </h3>
                <p className="my-auto xl:py-5 lg:py-6 py-5 flex-grow">{data.desc}</p>
                <div className="flex-grow flex flex-row-reverse space-x-4 space-x-reverse">
                    {data.links.map(link => (
                        <a href={link.url} key={link.url} className="font-semibold rounded bg-purple-500 p-2 pl-3 pr-3 text-white hover:bg-purple-800 active:bg-purple-900">
                            <i className={`${link.icon || 'bi-arrow-up-right-square'} align-text-bottom pr-1`}></i> {link.name}
                        </a>
                    ))}
                    <p className={`font-semibold text-gray-700 flex-grow ${data.links.length === 0 ? 'py-2 my-auto' : 'my-auto'}`}>{data.languages.join(', ')}</p>
                </div>
            </div>
        </div>
    )
}

export default ProjectCard;
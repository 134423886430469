import React from 'react';

const Header: React.FC<{}> = () => {
    return (
        <div className="mb-4 p-8 rounded bg-gradient-to-r from-indigo-500 to-purple-800 ">
            <div className="container mx-auto px-8">
                <h1 className="text-5xl text-white font-bold">Christian Harrypersad</h1>
                <h2 className="text-2xl text-white pt-2 font-light">Software Developer</h2>
            </div>
        </div>
    )
}

export default Header;